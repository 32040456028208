import { FC, ReactNode } from "react";
import Container from "shared/Container/Container";
import BackgroundStripe from "assets/background/BackgroundStripe/BackgroundStripe";
import BackgroundX from "assets/background/BackgroundX/BackgroundX";
import classes from "./background.module.scss";

interface BackgroundProps {
    children: ReactNode;
    bgImage: String;
}

const Background: FC<BackgroundProps> = ({ children, bgImage }) => {
    return (
        <div
            className={classes.picture}
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            <div className={classes.theme}></div>
            <div className={classes.overlay}>
                <BackgroundStripe />
                <BackgroundX />
            </div>
            <div className={classes.container}>
                <Container>{children}</Container>
            </div>
        </div>
    );
};

export default Background;
