import { FC } from "react";
import InterfaceFundingActivities from "utilities/interface/elements/fundingActivities.interface";
import TemplateContentWithImage from "shared/Template/ContentWithImage/ContentWithImage";
import CardContentWithList from "shared/Card/HeadingWithList/HeadingWithList";
import CardContent from "shared/Card/Content/Content";
import classes from "./element.module.scss";

interface ElementFundingActivitiesProps {
    element: InterfaceFundingActivities;
}

const ElementFundingActivities: FC<ElementFundingActivitiesProps> = ({
    element,
}) => {
    const {
        title,
        ternaryTitle,
        image,
        slideDescription,
        secondaryDescription,
    } = element;

    return (
        <TemplateContentWithImage
            heading={title}
            h3={ternaryTitle}
            picture={image.absoluteLink}
        >
            <div className={classes.wrapper}>
                <CardContentWithList data={slideDescription} />
                <CardContent data={secondaryDescription} />
            </div>
        </TemplateContentWithImage>
    );
};

export default ElementFundingActivities;
