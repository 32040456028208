import { useEffect, useRef, useState } from "react";
import client from "utilities/apollo/client";
import LANDING_PAGE from "utilities/graphQL/queries/page/landingPage";
import PageLoader from "shared/PageLoader/PageLoader";
import Background from "shared/Background/Background";
import Status from "./Status/Status";
// import IconPlay from "assets/icons/IconPlay";
import classes from "./page.module.scss";

const PageDashboard = () => {
    const videoRef = useRef<any>(null);
    const [loading, setLoading] = useState(true);
    const [landingPage, setLandingPage] = useState<any>();

    // const handleOnClick = () => {
    //     videoRef.current.play();
    // };

    useEffect(() => {
        const handleFetchPage = async () => {
            const { data } = await client.query({
                query: LANDING_PAGE,
            });
            setLandingPage(data.readLandingPage);
            setLoading(false);
        };

        handleFetchPage();
    });

    if (loading) {
        return <PageLoader loading={loading} />;
    }

    return (
        <Background bgImage={landingPage.backgroundImage.absoluteLink}>
            <div className={classes.wrapper}>
                <div className={classes.status}>
                    {!!landingPage.video.absoluteLink && <div className={classes.video}>
                        {/* <button onClick={handleOnClick}>
                            <IconPlay />
                        </button> */}
                        <video
                            ref={videoRef}
                            controls
                            poster={landingPage.videoThumb.absoluteLink}
                        >
                            <source
                                src={`${landingPage.video.absoluteLink}`}
                                type="video/mp4"
                            />
                        </video>
                    </div>}
                    <Status
                        heading={landingPage.title}
                        range={landingPage.subTitle}
                    />
                </div>
            </div>
        </Background>
    );
};

export default PageDashboard;
