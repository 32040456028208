import { FC, ReactNode } from "react";
import classes from "./template.module.scss";

interface TemplateTableProps {
    heading?: string;
    subHeading?: string;
    content?: string;
    table?: any;
    children?: ReactNode;
}

const TemplateTable: FC<TemplateTableProps> = ({
    heading,
    subHeading,
    content,
    table,
    children,
}) => {
    return (
        <div className={classes.wrapper}>
            <h1>{heading}</h1>
            {/* {subHeading && <h2>{subHeading}</h2>} */}
            <div className={classes.slider}>
                <div className={classes.table}>
                    {content && (
                        <div
                            dangerouslySetInnerHTML={{ __html: content }}
                        ></div>
                    )}
                    {table && <div>{table}</div>}
                </div>
            </div>
            {children}
        </div>
    );
};

export default TemplateTable;
