const PROJECT_TRACKER = `
... on ElementProjectTrackerSlide {
    id
    title
    projects {
        nodes {
            id
            title
            status
            linkPage {
                title
                appRelativeLink
            }
        }
    }
} `;

export default PROJECT_TRACKER;
