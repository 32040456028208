import { FC, Fragment, useState } from "react";
import Carousal from "shared/Carousal/Carousal";
import ElementBarChart from "./BarChart/BarChart";
import ElementComplexGraph from "./ComplexGraph/ComplexGraph";
import ElementContent from "./Content/Content";
import ElementFinancial from "./Financial/Financial";
import ElementFundingActivities from "./FundingActivities/FundingActivities";
import ElementImageContent from "./ImageContent/ImageContent";
import ElementPieChart from "./PieChart/PieChart";
import ElementProjectTracker from "./ProjectTracker/ProjectTracker";
import ElementQuarterlyUpdate from "./QuarterlyUpdate/QuarterlyUpdate";
import classes from "./elements.module.scss";

interface ElementsProps {
    data: any;
}

interface Popup {
    image: string;
    content: string;
}

const Elements: FC<ElementsProps> = ({ data }) => {
    const elements = data?.map((element: any) => element.node);

    const [popup, setPopup] = useState<Popup | undefined>(undefined);

    return elements.length > 0 ? (
        <Fragment>
            <Carousal>
                {elements?.map((element: any) => {
                    switch (element.__typename) {
                        case "ElementFinancialSlide":
                            return (
                                <ElementFinancial
                                    key={element.id}
                                    element={element}
                                />
                            );
                        case "ElementPieChartSlide":
                            return (
                                <ElementPieChart
                                    key={element.id}
                                    element={element}
                                />
                            );
                        case "ElementBarChartSlide":
                            return (
                                <ElementBarChart
                                    key={element.id}
                                    element={element}
                                />
                            );
                        case "ElementFundingActivitySlide":
                            return (
                                <ElementFundingActivities
                                    key={element.id}
                                    element={element}
                                />
                            );
                        case "ElementComplexGraphSlide":
                            return (
                                <ElementComplexGraph
                                    key={element.id}
                                    element={element}
                                />
                            );
                        case "ElementQuarterlyUpdatesSlide":
                            return (
                                <ElementQuarterlyUpdate
                                    key={element.id}
                                    element={element}
                                    setPopup={setPopup}
                                />
                            );
                        case "ElementContent":
                            return (
                                <ElementContent
                                    key={element.id}
                                    element={element}
                                />
                            );
                        case "ElementProjectTrackerSlide":
                            return (
                                <ElementProjectTracker
                                    key={element.id}
                                    element={element}
                                />
                            );
                        case "ElementImageContent":
                            return (
                                <ElementImageContent
                                    key={element.id}
                                    element={element}
                                />
                            );
                        default:
                            return null;
                    }
                })}
            </Carousal>

            {!!popup && (
                <div className={classes.overlay}>
                    <div className={classes.overlayInner}>
                        <div
                            className={classes.close}
                            onClick={() => {
                                setPopup(undefined);
                            }}
                        >
                            X
                        </div>
                        <div className={classes.body}>
                            {popup.image && popup.image !== "" && (
                                <div className={classes.cols}>
                                    <div className={classes.col}>
                                        <img src={popup.image} alt="" />
                                    </div>
                                    <div className={classes.col}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: popup.content,
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {!(popup.image && popup.image !== "") && (
                                <div className={classes.cols}>
                                    <div
                                        className={`${classes.col} ${classes.colFull}`}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: popup.content,
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    ) : null;
};

export default Elements;
