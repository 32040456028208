const COMPLEX_GRAPH = `
    ... on ElementComplexGraphSlide {
        id
        title
        secondaryTitle
        ternaryTitle
        mainContent
        slideDescription
        secondaryDescription
        graph {
            title
            name
            url
            absoluteLink
            width
            height
        }
    } `;

export default COMPLEX_GRAPH;
