import { useEffect, useId, useState } from "react";
import { Routes, Route } from "react-router-dom";
import client from "utilities/apollo/client";
import NAVIGATION from "utilities/graphQL/queries/Navigation/navigation";
import SITE_TREE from "utilities/graphQL/queries/page/siteTree";
import AuthGuard from "pages/AuthGuard/AuthGuard";
import Layout from "shared/Layout/Layout";
import PageDashboard from "pages/Dashboard/Dashboard";
import PageSection from "pages/Section/Section";
import PageLogin from "pages/Login/Login";
import PageLoader from "shared/PageLoader/PageLoader";

const App = () => {
    const id = useId();
    const [list, setList] = useState<any[]>([]);
    const [siteTree, setSiteTree] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const handleFetchNavigation = async () => {
            setLoading(true);
            const { data } = await client.query({
                query: NAVIGATION,
            });
            const siteTreeData = await client.query({
                query: SITE_TREE,
            });

            setList(data.readNavigation);
            setSiteTree(siteTreeData.data.readPages.edges);
            setLoading(false);
        };

        handleFetchNavigation();
    }, []);

    if (loading) {
        return <PageLoader loading={loading} />;
    }

    const pagesList = [];
    for (const navItem of list) {
        for (const subNavItem of navItem.children) {
            pagesList.push(subNavItem);
        }
        if (navItem.appRelativeLink !== "/") {
            pagesList.push(navItem);
        }
    }

    return (
        <Routes>
            {/* 404 Not Found Page */}
            {/*<Route path="*" element={<>No match </>} />*/}

            {/* Public Page */}
            <Route path="/login" element={<PageLogin />} />

            {/* Private Page*/}
            <Route
                path="/"
                element={
                    <AuthGuard>
                        <Layout>
                            <PageDashboard />
                        </Layout>
                    </AuthGuard>
                }
            ></Route>

            {siteTree.map((pageData: any) => {
                return (
                    <Route
                        key={id}
                        path={pageData.node.appRelativeLink}
                        element={
                            <AuthGuard>
                                <PageSection
                                    pageId={pageData.node.appRelativeLink}
                                />
                            </AuthGuard>
                        }
                    />
                );
            })}
        </Routes>
    );
};

export default App;
