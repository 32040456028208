import { FC } from "react";
import InterfaceFinancial from "utilities/interface/elements/financial.interface";
import TemplateTable from "shared/Template/Table/Table";
import CardContent from "shared/Card/Content/Content";
import classes from "./element.module.scss";

interface ElementFinancialProps {
    element: InterfaceFinancial;
}

const ElementFinancial: FC<ElementFinancialProps> = ({ element }) => {
    const { title, slideDescription, dataTable, takes, suffix } = element;

    return (
        <TemplateTable
            heading={title}
            content={slideDescription}
            table={
                <div
                    className={classes.tableFinancial}
                    dangerouslySetInnerHTML={{
                        __html: dataTable,
                    }}
                ></div>
            }
        >
            <div className={classes.wrapper}>
                <div className={classes.image}>
                    <img src={takes.absoluteLink} alt="placeholder" />
                </div>
                <CardContent data={suffix} />
            </div>
        </TemplateTable>
    );
};

export default ElementFinancial;
