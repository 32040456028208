import { FC } from "react";
import InterfaceBarChart from "utilities/interface/elements/barChart.interface";
import TemplateContentWithImage from "shared/Template/ContentWithImage/ContentWithImage";
import CardList from "shared/Card/List/List";
import CardContent from "shared/Card/Content/Content";
import classes from "./element.module.scss";

interface ElementBarChartProps {
    element: InterfaceBarChart;
}

const ElementBarChart: FC<ElementBarChartProps> = ({ element }) => {
    const { title, barChart, slideDescription, secondaryDescription } = element;

    return (
        <TemplateContentWithImage
            heading={title}
            picture={barChart.absoluteLink}
        >
            <div className={classes.wrapper}>
                <CardList data={slideDescription} />
                <CardContent data={secondaryDescription} />
            </div>
        </TemplateContentWithImage>
    );
};

export default ElementBarChart;
