import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import InterfaceBreadcrumb from "utilities/interface/breadcrumb.interface";
import { MdNavigateNext } from "react-icons/md";
import classes from "./dropdown.module.scss";

interface DropdownProps {
    data: any[];
    dropdownChange?: Function;
    setOpen?: Function
}

const Dropdown: FC<DropdownProps> = ({ data, dropdownChange, setOpen }) => {
    const navigate = useNavigate();
    const [list, setList] = useState<any[]>([]);
    const breadcrumb = useReadLocalStorage("breadcrumb") as InterfaceBreadcrumb;
    const [local, setLocal] = useLocalStorage("breadcrumb", breadcrumb);
    const [carousel, setCarousel] = useLocalStorage("carousel", false);

    useEffect(() => {
        setList(data);
    }, [data]);

    const handleOnView = useCallback(
        (value: string) => {
            setLocal({ ...local, title: value });
            navigate(`/${value}`);
        },
        [navigate, local, setLocal]
    );

    return (
        <div className={classes.list}>
            {list?.map((listItem: any) => (
                <div
                    key={listItem.id}
                    className={classes.listItem}
                    onClick={() => {
                        handleOnView(listItem.appRelativeLink);
                        setCarousel(true);
                        if (dropdownChange) {
                            dropdownChange(-1)
                        }
                        if (setOpen) {
                            setOpen(false)
                        }
                    }}
                >
                    {listItem.navigationIcon ? (
                        <div className={classes.iconCharacter}>
                            <span>{listItem.navigationIcon}</span>
                        </div>
                    ) : (
                        <div className={classes.iconMock}>
                            <MdNavigateNext size={20} />
                        </div>
                    )}

                    <div className={classes.content}>
                        <h6 className={classes.heading}>{listItem.title}</h6>
                        <span className={classes.info}>
                            {listItem.navigationDescription}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Dropdown;
