import { FC, ReactNode } from "react";
import classes from "./template.module.scss";

interface TemplateContentWithImageProps {
    heading?: string;
    h2?: string;
    h3?: string;
    content?: any;
    picture?: string;
    children?: ReactNode;
}

const TemplateContentWithImage: FC<TemplateContentWithImageProps> = ({
    heading,
    h2,
    h3,
    content,
    picture,
    children,
}) => {
    return (
        <div className={classes.wrapper}>
            <h1>{heading}</h1>
            {h2 && <h2>{h2}</h2>}
            {h3 && <h3>{h3}</h3>}
            <div className={classes.slider}>
                <div className={classes.content}>
                    {picture && (
                        <div>
                            <img
                                src={picture}
                                className={classes.chartImage}
                                alt="report"
                            />
                        </div>
                    )}

                    {children && <div>{children}</div>}
                </div>
            </div>
        </div>
    );
};

export default TemplateContentWithImage;
