import { Dispatch, FC, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import CREATE_LOGIN from "utilities/graphQL/mutations/create";
import classes from "./form.module.scss";

interface FormData {
    email: string;
    otp: string;
}

const schema = yup
    .object({
        email: yup
            .string()
            .required("Email must be required")
            .email("Email must be valid"),
    })
    .required();

interface FormProps {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEmail: Dispatch<SetStateAction<string>>;
}

const Form: FC<FormProps> = ({ setOpen, setEmail }: any) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const [sendLoginRequest] = useMutation(CREATE_LOGIN);

    const onSubmit = (data: FormData) => {
        const { email } = data;
        setEmail(email);
        sendLoginRequest({ variables: { email } }).then((res) => {
            setOpen(res.data.sendLoginRequest.valid);
        });
    };

    return (
        <form
            id="formLogin"
            name="formLogin"
            noValidate
            onSubmit={(event) => {
                event?.stopPropagation();
                handleSubmit(async (values, event) => {
                    onSubmit(values as FormData);
                })(event);
            }}
        >
            <div className={classes.wrapper}>
                <h1>TOI FOUNDATION</h1>
                <h3>Sign in</h3>
                <div>
                    <input
                        type="email"
                        placeholder="Email"
                        {...register("email")}
                    />
                    <span>{errors?.email?.message}</span>
                </div>

                <button form="formLogin" type="submit" className="btn-primary">
                    Submit
                </button>
            </div>
        </form>
    );
};

export default Form;
