import { FC } from "react";
import classes from "./card.module.scss";

interface ContentWithListProps {
    data: any;
}

const CardContentWithList: FC<ContentWithListProps> = ({ data }) => {
    return (
        <div className={classes.content}>
            <div dangerouslySetInnerHTML={{ __html: data }}></div>
        </div>
    );
};

export default CardContentWithList;
