import { FC } from "react";
import InterfaceComplexGraph from "utilities/interface/elements/complexGraph.interface";
import TemplateContentWithImage from "shared/Template/ContentWithImage/ContentWithImage";
import CardContent from "shared/Card/Content/Content";
import classes from "./element.module.scss";

interface ElementComplexGraphProps {
    element: InterfaceComplexGraph;
}

const ElementComplexGraph: FC<ElementComplexGraphProps> = ({ element }) => {
    const {
        title,
        ternaryTitle,
        mainContent,
        graph,
        slideDescription,
        secondaryDescription,
    } = element;

    return (
        <TemplateContentWithImage
            heading={title}
            h3={ternaryTitle}
            content={mainContent}
            picture={graph.absoluteLink}
        >
            <div className={classes.wrapper}>
                <CardContent data={slideDescription} />
                <CardContent data={secondaryDescription} />
            </div>
        </TemplateContentWithImage>
    );
};

export default ElementComplexGraph;
