import { FC } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import classes from "./pageLoader.module.scss";

interface PageLoaderProps {
    loading: boolean;
}

const PageLoader: FC<PageLoaderProps> = ({ loading }) => {
    return (
        <div className={classes.wrapper}>
            <div>
                <ScaleLoader color={"#333F34"} loading={loading} />
                <h4>Loading</h4>
            </div>
        </div>
    );
};

export default PageLoader;
