import { FC } from "react";
import classes from "./card.module.scss";

interface ListProps {
    data: any;
}

const CardList: FC<ListProps> = ({ data }) => {
    return (
        <div className={classes.content}>
            <div dangerouslySetInnerHTML={{ __html: data }}></div>
        </div>
    );
};

export default CardList;
