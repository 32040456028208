import { FC, ReactNode } from "react";
import classes from "./template.module.scss";

interface TemplateImageGalleryProps {
    heading?: string;
    subHeading?: string;
    ternaryHeading?: string;
    pictures?: any;
    children?: ReactNode;
}

const TemplateImageGallery: FC<TemplateImageGalleryProps> = ({
    heading,
    subHeading,
    ternaryHeading,
    pictures,
    children,
}) => {
    return (
        <div className={classes.wrapper}>
            <h1>{heading}</h1>
            {subHeading && <h2>{subHeading}</h2>}
            {ternaryHeading && <h3>{ternaryHeading}</h3>}
            <div className={classes.slider}>
                <div className={classes.content}>
                    {pictures && (
                        <div>
                            {pictures.map((picture: any, index: number) => (
                                <img
                                    key={index}
                                    src={picture.absoluteLink}
                                    className={classes.chartImage}
                                    alt={`img-${index + 1}`}
                                />
                            ))}
                        </div>
                    )}
                    {children && <div>{children}</div>}
                </div>
            </div>
        </div>
    );
};

export default TemplateImageGallery;
