import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useReadLocalStorage } from "usehooks-ts";
import InterfaceAuth from "utilities/interface/auth.interface";

interface AuthGuardProps {
    children: JSX.Element;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
    const auth = useReadLocalStorage("auth") as InterfaceAuth;
    let location = useLocation();

    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default AuthGuard;
