import { gql } from "@apollo/client";

const CREATE_LOGIN = gql`
    mutation SendLoginRequest($email: String!) {
        sendLoginRequest(email: $email) {
            valid
        }
    }
`;

export default CREATE_LOGIN;
