const FINANCIAL = `
    ... on ElementFinancialSlide {
        id
        title
        subTitle
        slideDescription
        suffix
        dataTable
        takes {
            title
            name
            url
            absoluteLink
            width
            height
        }
    } `;

export default FINANCIAL;
